import React from 'react';
import {hydrate, render} from "react-dom";
import App from './javascripts/components/App';
import * as serviceWorker from './serviceWorker';
import ReactModal from 'react-modal';

import './styles/index.scss';
import './javascripts/public_site';
import {BASE_URL} from "./javascripts/constants/EnvVariables";
import Cookies from "js-cookie";


if (navigator.userAgent === "ReactSnap" && process.env.REACT_APP_BUILD_TOKEN) {
    Cookies.set('site_builder_token', process.env.REACT_APP_BUILD_TOKEN);
} else if (navigator.userAgent === "ReactSnap" && !Cookies.get('site_builder_token') && process.env.REACT_APP_BUILD_MODE === 'production') {

    window.$.ajax({
        type: "POST",
        dataType: 'json',
        url: BASE_URL + "api/login",
        data: {email: process.env.REACT_APP_BUILD_LOGIN, password: process.env.REACT_APP_BUILD_PASSWORD},

        success: function (data) {
            Cookies.set('site_builder_token', data.data.token);
            window.location.href = "/"
        },

        error: function (data) {
        }
    });
}

if (process.env.REACT_APP_BUILD_MODE !== 'production_assets') {
    const rootElement = document.getElementById("root");
    ReactModal.setAppElement(rootElement);

    if (rootElement.hasChildNodes()) {
        hydrate(<App/>, rootElement);
    } else {
        render(<App/>, rootElement);
    }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
