import { Controller } from 'stimulus';
// import lazyLoad from '../lib/image-lazy-load';

export default class ViewMoreController extends Controller {
  static targets = ['container', 'button'];

  processData(doc, id) {
    let newContentId = false;
    if (!doc) {
      return;
    }
    if (id) {
      newContentId = doc.querySelector(`#${id}`);
    }

    const newContent = id ? newContentId.querySelector(`[data-view-more-target="${this.containerTarget.dataset.viewMoreTarget}"]`)
      : doc.querySelector(`[data-view-more-target="${this.containerTarget.dataset.viewMoreTarget}"]`);
    const newButton = id ? newContentId.querySelector(`[data-view-more-target="${this.buttonTarget.dataset.viewMoreTarget}"]`)
      : doc.querySelector(`[data-view-more-target="${this.buttonTarget.dataset.viewMoreTarget}"]`);

    if (newContent) {
      const fragment = document.createDocumentFragment();
      while (newContent.children.length > 0) {
        fragment.appendChild(newContent.children[0]);
      }

      this.containerTarget.appendChild(fragment);
    //   lazyLoad.update();
    }

    if (newButton) {
      this.buttonTarget.href = newButton.href;
    } else {
      this.buttonTarget.classList.add('hidden');
    }
  }

  load(event) {
    event.preventDefault();
    const url = this.buttonTarget.href;
    const idDate = this.buttonTarget.dataset.id || false;
    this.buttonTarget.classList.add('disabled');
    document.body.classList.add('loading');
    this.buttonTarget.blur();
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        const parser = new DOMParser();
        this.processData(parser.parseFromString(text, 'text/html'), idDate);
      })
      .finally(() => {
        this.buttonTarget.classList.remove('disabled');
        document.body.classList.remove('loading');
      });
  }
}
