import * as types from '../constants/ActionTypes';

const initialState =  [];

export function organisationCategories(state, action) {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ORGANIZATION_CATEGORIES_STARTED:
      return state;

    case types.GET_ORGANIZATION_CATEGORIES_SUCCESS:
      return action.info;

    case types.GET_ORGANIZATION_CATEGORIES_ERROR:
      return state;

    default:
      return state;
  }
}

export default organisationCategories
