import * as types from '../constants/ActionTypes';
import Cookies from "js-cookie";
import {BASE_URL} from "../constants/EnvVariables";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getManagement() {
    return dispatch => {
        dispatch({
            type: types.GET_MANAGEMENT_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/managers",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_MANAGEMENT_SUCCESS,
                    payload: {management: data},
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_MANAGEMENT_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });

    }
}

export function setManagement(payload) {
    let originPayload = [...payload];
    payload.forEach(function (currentValue, index) {
        payload[index] = snakeKeys(currentValue);
        delete payload[index].level
    });

    if(payload.length > 0){
        return dispatch => {
            dispatch({
                type: types.SET_MANAGEMENT_STARTED
            });

            if(!window.editBlocked){
                return Promise.resolve(window.$.ajax({
                    type: "PUT",
                    dataType: 'json',
                    url: BASE_URL + "api/managers/" + payload[0].id + "?_method=PUT",
                    data: {management: payload},
                    headers: {
                        'Authorization': "Bearer " + Cookies.get('site_builder_token')
                    },
                    success: function (data, status, xhr) {
                        dispatch({
                            type: types.SET_MANAGEMENT_SUCCESS,
                            payload: originPayload,
                        });
                    },

                    error: function (data) {
                        ajaxErrorRedirect(data);

                        dispatch({
                            type: types.SET_MANAGEMENT_ERROR,
                            message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                        });
                    }
                }));
            }

        }
    }
}

export function deleteManager(id) {
    return {
        type: types.DELETE_MANAGER,
        payload: id,
    }
}

export function clearManagement() {
    return dispatch => {
        dispatch({
            type: types.GET_MANAGEMENT_SUCCESS,
            payload: {management: []},
        });
    };
}