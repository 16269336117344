import * as types from '../constants/ActionTypes';
import {BASE_URL} from '../constants/EnvVariables';
import Cookies from 'js-cookie';
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getFood() {
    return dispatch => {
        dispatch({
            type: types.GET_FOOD_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/food",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_FOOD_SUCCESS,
                    info: {food: data},
                });
            },

            error: function (data) {
                dispatch({
                    type: types.GET_FOOD_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });

                ajaxErrorRedirect(data);

            }
        });

    };
}
export function clearFood() {
    return dispatch => {
        dispatch({
            type: types.GET_FOOD_SUCCESS,
            info: {food: []},
        });
    };
}

export default getFood
