import * as types from '../constants/ActionTypes';

const initialState = {
    structure: []
};


function immutablySwapItems(items, type, slug) {
    let startIndexElement = undefined;
    let firstIndex = undefined;
    let secondIndex = undefined;

    let index = 0;

    while (index < items.length) {
        if (items[index].slug === slug) {
            startIndexElement = index;
            break
        }
        index++;
    }

    if (type === 'top' && startIndexElement > 0) {
        firstIndex = startIndexElement - 1;
        secondIndex = startIndexElement;

    } else if (type === 'bottom' && startIndexElement < items.length - 1) {
        firstIndex = startIndexElement + 1;
        secondIndex = startIndexElement;
    }

    let results = items;

    if (firstIndex !== undefined && secondIndex !== undefined) {
        results = items.slice();
        const firstItem = items[firstIndex];
        results[firstIndex] = items[secondIndex];
        results[secondIndex] = firstItem;
    }


    return results;
}

export function structure(state, action) {
    let newStructure = [];
    state = state || initialState;

    switch (action.type) {
        case types.GET_STRUCTURE_STARTED:
            return state;

        case types.GET_STRUCTURE_SUCCESS:
            return action.info;

        case types.GET_STRUCTURE_ERROR:
            return state;

        case types.ADD_STRUCTURE_PAGE_STARTED:
            return state;

        case types.ADD_STRUCTURE_PAGE_SUCCESS:
            newStructure = state.structure && state.structure.length > 0 ? state.structure.map(
                (firstLevel) => action.deepLevels.firsLevelSlug === firstLevel.slug ?
                    !action.deepLevels.hasOwnProperty('secondLevelSlug') ? {
                            ...firstLevel,
                            pages: [...firstLevel.pages, action.info]
                        } :
                        {
                            ...firstLevel,
                            pages: firstLevel.pages.map(
                                (secondLevel) => action.deepLevels.secondLevelSlug === secondLevel.slug ?
                                    !action.deepLevels.hasOwnProperty('thirdLevelSlug') ? {
                                            ...secondLevel,
                                            pages: [...secondLevel.pages, action.info]
                                        } :
                                        {
                                            ...secondLevel,
                                            pages: secondLevel.pages.map(
                                                (thirdLevel) => action.deepLevels.thirdLevelSlug === thirdLevel.slug ?
                                                    !action.deepLevels.hasOwnProperty('fourthLevelSlug') ? {
                                                            ...thirdLevel,
                                                            pages: [...thirdLevel.pages, action.info]
                                                        } :
                                                        {
                                                            ...thirdLevel,
                                                            pages: thirdLevel.pages.map(
                                                                (fourthLevel) => action.deepLevels.fourthLevelSlug === fourthLevel.slug ? {
                                                                        ...fourthLevel,
                                                                        pages: [...fourthLevel.pages, action.info]
                                                                    }
                                                                    : fourthLevel)
                                                        } : thirdLevel)
                                        } : secondLevel)
                        } : firstLevel
            ) : [];

            return {
                ...state,
                structure: newStructure
            };

        case types.ADD_STRUCTURE_PAGE_ERROR:
            return state;

        case types.EDIT_STRUCTURE_PAGE_STARTED:
            return state;

        case types.EDIT_STRUCTURE_PAGE_SUCCESS:
            newStructure = state.structure && state.structure.length > 0 ? state.structure.map(
                (firstLevel) => action.deepLevels.firsLevelSlug === firstLevel.slug ?
                    !action.deepLevels.hasOwnProperty('secondLevelSlug') ? {
                            ...firstLevel,
                            name: action.info.name,
                            slug: action.info.slug,
                            url: action.info.url,
                            active: action.info.active,
                            editable: action.info.editable
                        } :
                        {
                            ...firstLevel,
                            pages: firstLevel.pages.map(
                                (secondLevel) => action.deepLevels.secondLevelSlug === secondLevel.slug ?
                                    !action.deepLevels.hasOwnProperty('thirdLevelSlug') ? {
                                            ...secondLevel,
                                            name: action.info.name,
                                            slug: action.info.slug,
                                            url: action.info.url,
                                            active: action.info.active,
                                            editable: action.info.editable
                                        } :
                                        {
                                            ...secondLevel,
                                            pages: secondLevel.pages.map(
                                                (thirdLevel) => action.deepLevels.thirdLevelSlug === thirdLevel.slug ?
                                                    !action.deepLevels.hasOwnProperty('fourthLevelSlug') ? {
                                                            ...thirdLevel,
                                                            name: action.info.name,
                                                            slug: action.info.slug,
                                                            url: action.info.url,
                                                            active: action.info.active,
                                                            editable: action.info.editable
                                                        } :
                                                        {
                                                            ...thirdLevel,
                                                            pages: thirdLevel.pages.map(
                                                                (fourthLevel) => action.deepLevels.fourthLevelSlug === fourthLevel.slug ? {
                                                                        ...fourthLevel,
                                                                        name: action.info.name,
                                                                        slug: action.info.slug,
                                                                        url: action.info.url,
                                                                        active: action.info.active,
                                                                        editable: action.info.editable
                                                                    }
                                                                    : fourthLevel)
                                                        } : thirdLevel)
                                        } : secondLevel)
                        } : firstLevel
            ) : [];

            return {
                ...state,
                structure: newStructure
            };

        case types.EDIT_STRUCTURE_PAGE_ERROR:
            return state;

        case types.DELETE_STRUCTURE_PAGE_STARTED:
            return state;

        case types.DELETE_STRUCTURE_PAGE_SUCCESS:
            newStructure = state.structure && state.structure.length > 0 ? state.structure.map(
                (firstLevel) => action.deepLevels.firsLevelSlug === firstLevel.slug ?
                    !action.deepLevels.hasOwnProperty('secondLevelSlug') ? undefined :
                        {
                            ...firstLevel,
                            pages: firstLevel.pages.map(
                                (secondLevel) => action.deepLevels.secondLevelSlug === secondLevel.slug ?
                                    !action.deepLevels.hasOwnProperty('thirdLevelSlug') ?
                                        undefined :
                                        {
                                            ...secondLevel,
                                            pages: secondLevel.pages.map(
                                                (thirdLevel) => action.deepLevels.thirdLevelSlug === thirdLevel.slug ?
                                                    !action.deepLevels.hasOwnProperty('fourthLevelSlug') ? undefined :
                                                        {
                                                            ...thirdLevel,
                                                            pages: thirdLevel.pages.map(
                                                                (fourthLevel) => action.deepLevels.fourthLevelSlug === fourthLevel.slug ? undefined
                                                                    : fourthLevel).filter(n => n)
                                                        } : thirdLevel).filter(n => n)
                                        } : secondLevel).filter(n => n)
                        } : firstLevel
            ).filter(n => n) : [];

            return {
                ...state,
                structure: newStructure
            };

        case types.DELETE_STRUCTURE_PAGE_ERROR:
            return state;

        case types.UPDATE_PRIORITY_STRUCTURE_PAGE_STARTED:
            return state;

        case types.UPDATE_PRIORITY_STRUCTURE_PAGE_SUCCESS:
            if (!action.deepLevels.hasOwnProperty('secondLevelSlug')) {
                newStructure = immutablySwapItems(state.structure, action.info.type, action.deepLevels.firsLevelSlug)
            } else {
                newStructure = state.structure && state.structure.length > 0 ?state.structure.map((firstLevel, index) => action.deepLevels.firsLevelSlug === firstLevel.slug ? !action.deepLevels.hasOwnProperty('thirdLevelSlug') ? {
                        ...firstLevel,
                        pages: immutablySwapItems(firstLevel.pages, action.info.type, action.deepLevels.secondLevelSlug)
                    } :
                    {
                        ...firstLevel,
                        pages: firstLevel.pages.map((secondLevel, index) => action.deepLevels.secondLevelSlug === secondLevel.slug ? !action.deepLevels.hasOwnProperty('fourthLevelSlug') ? {
                                ...secondLevel,
                                pages: immutablySwapItems(secondLevel.pages, action.info.type, action.deepLevels.thirdLevelSlug)
                            } :

                            {
                                ...secondLevel,
                                pages: secondLevel.pages.map((thirdLevel, index) => action.deepLevels.thirdLevelSlug === thirdLevel.slug ? {
                                    ...thirdLevel,
                                    pages: immutablySwapItems(thirdLevel.pages, action.info.type, action.deepLevels.fourthLevelSlug)
                                } : firstLevel)
                            }

                            : secondLevel)
                    }

                    : state) : []
            }

            return {
                ...state,
                structure: newStructure
            };

        case types.UPDATE_PRIORITY_STRUCTURE_PAGE_ERROR:
            return state;


        default:
            return state;
    }
}

export default structure
