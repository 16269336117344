import {Controller} from 'stimulus';
import Swiper from 'swiper';
import {
    Autoplay, Navigation, Pagination, Virtual, Controller as SwiperController,
} from 'swiper/core';
// import lazyLoad from '../lib/image-lazy-load';

export default class ArticleSliderController extends Controller {
    static targets = ['prev', 'next', 'slider', 'slide', 'wrapper', 'pagination'];

    slideInfo = [];

    slideIndex = 0;

    typeGallery = 'album';

    controllers = document.querySelectorAll('.article__media-controller');

    recalculateHandler = () => window.requestAnimationFrame(this.recalculate);

    connect() {
        this.mainSliderInit();
    }

    disconnect() {
        window.removeEventListener('resize', this.recalculateHandler);
        this.swiper.destroy();
        this.swiper = undefined;
        if (this.photoSlider) {
            if (Array.isArray(this.photoSlider)) {
                this.photoSlider.forEach((element, index) => {
                    this.photoSlider[index].destroy();
                    this.photoSlider[index] = undefined;
                });
            } else {
                if (window.$(this.photoSlider.el).length) {
                    this.photoSlider.destroy();
                    this.photoSlider = undefined;
                }
            }

        }
    }

    mainSliderInit() {
        window.addEventListener('resize', this.recalculateHandler);
        this.getSlide();
        if (this.controllers.length > 0) {
            this.controllers.forEach((element) => {
                if (element.classList.contains('active')) {
                    if (element.dataset.type === 'slider') {
                        this.typeGallery = 'slider';
                        this.sliderInit();
                        // lazyLoad.update();
                    } else {
                        if (this.slideTargets.length > 4) {
                            this.wrapperTarget.classList.add('gallery--long');
                        }
                        document.querySelector('.article-slider__wrapper').classList.add('article__gallery-wrapper');
                    }
                }
            });
        } else {
            this.sliderInit();
        }
        for (let i = 0; i < this.slideTargets.length; i++) {
            this.slideTargets[i].addEventListener('click', () => {
                if (this.typeGallery === 'album') {
                    this.photoSlider.slideToLoop(i, 0, false);
                }
            });
        }


        let self = this;
        let idModal = window.$(this.sliderTarget).attr('data-idModal');

        if(idModal){
            this.photoSlider = new Swiper(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__slider')[0], {
                modules: [Navigation, Pagination, Virtual, SwiperController],
                slidesPerView: 1,
                loop: this.slideTargets.length > 1,
                autoHeight: true,
                navigation: {
                    nextEl: window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-slider__button--next')[0],
                    prevEl: window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-slider__button--prev')[0],
                },
                pagination: {
                    el: window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__pagination')[0],
                    type: 'custom',
                    renderCustom(swiper, current, total) {
                        return `${current} из ${total}`;
                    },
                },
                breakpoints: {
                    768: {
                        autoHeight: false,
                    },
                },
                on: {
                    slideChange: function (swiper) {
                        self.slideIndex = swiper.realIndex;
                        self.infoSlide();
                    },
                },
            });
        }



        const controllers = document.querySelectorAll('.article__media-controller');

        if (this.realSlides > 4) {
            this.wrapperTarget.classList.add('gallery--long');
        }

        controllers.forEach((element) => {
            element.addEventListener('click', () => {
                controllers.forEach((button) => {
                    button.classList.remove('active');
                });
                element.classList.add('active');
                if (element.dataset.type === 'album') {
                    this.sliderDestroy();
                } else {
                    document.querySelector('.article-slider__wrapper').classList.remove('article__gallery-wrapper');
                    this.sliderInit();
                }
            });
        });
        window.setTimeout(() => this.imageLoadingWithLazy(), 100);
        this.infoSlide();
    }

    imageLoadingWithLazy() {
        document.querySelectorAll('.swiper-slide-duplicate').forEach((element) => {
            if (element.children[0].classList.contains('article-slide__illustration')) {
                if (element.children[0].classList.contains('article-slide__illustration')) {
                    const webpSrc = element.children[0].querySelector('.article-slide__illustration-image.lazy').getAttribute('data-src-webp') || false;
                    if (webpSrc) element.children[0].querySelector('.article-slide__illustration-image.lazy').setAttribute('data-src', webpSrc);
                    element.children[0].querySelector('.article-slide__illustration-image.lazy').setAttribute('src', element.children[0].querySelector('.article-slide__illustration-image.lazy').getAttribute('data-src'));
                } else {
                    const webpSrc = element.children[0].getAttribute('data-src-webp') || false;
                    if (webpSrc) element.children[0].setAttribute('data-src', webpSrc);
                    element.children[0].setAttribute('src', element.children[0].getAttribute('data-src'));
                }
            }
        });
    }

    sliderDestroy = () => {
        this.swiper.destroy();
        this.swiper = undefined;
        document.querySelector('.article-slider__wrapper').classList.add('article__gallery-wrapper');
        this.typeGallery = 'album';
    };

    recalculate = () => {
        window.requestAnimationFrame(() => {
            if ((this.typeGallery === 'slider' || this.typeGallery === 'banners') && this.slideTargets[0] && this.swiper) {
                if (this.typeGallery !== 'banners') {
                    let containerWidth = window.$('.article__content, .constructor-component__content').first().width();
                    let containerWidthMobile = window.getComputedStyle(document.querySelector('.article__content, .constructor-component__content'));

                    if (containerWidth === 0) {
                        containerWidth = window.$('.article-slider__wrapper').first().width();
                        containerWidthMobile = window.getComputedStyle(document.querySelector('.article-slider__wrapper'));
                    }

                    this.slideTargets.forEach((element) => {
                        element.style.width = window.innerWidth > 768 ? `${containerWidth}px` : `${parseInt(containerWidthMobile.width, 10) - (parseInt(containerWidthMobile.paddingLeft, 10) * 2)}px`;
                    });
                }
                this.swiper.update();
                this.swiper.slideToLoop(0, 0, false);
                if (this.typeGallery === 'slider') {
                    this.wrapperTarget.style.height = `${this.slideTargets[0].querySelector('.article-slide__illustration').offsetHeight}px`;
                    if (this.slideTargets.length > 1) {
                        const topPosition = this.slideTargets[0].querySelector('.article-slide__illustration').offsetHeight / 2 - 24;
                        this.prevTarget.style.top = `${topPosition}px`;
                        this.nextTarget.style.top = `${topPosition}px`;
                    }
                }
                if (this.controllers.length > 0) {
                    if (window.innerWidth <= 768) {
                        this.sliderDestroy();
                        this.controllers.forEach((element) => {
                            element.classList.add('active');
                            if (element.dataset.type === 'slider') {
                                element.classList.remove('active');
                            }
                        });
                    }
                }
            }
        });
    };

    sliderInit() {

        this.typeGallery = this.sliderTarget.dataset.type === 'banners' ? 'banners' : 'slider';
        if (this.slideTargets.length === 1 && this.typeGallery !== 'banners') {
            window.$('.article-slider__pagination-wrapper').css('display', 'none');
        }

        let paginationSlider = false;
        let typePagination = this.sliderTarget.dataset.typePagination;

        if (typePagination === 'lines') {
            paginationSlider = {
                el: this.paginationTarget,
                type: 'bullets',
                clickable: true
            }
        } else if (this.typeGallery === 'slider') {
            paginationSlider = {
                el: this.paginationTarget,
                type: 'custom',
                renderCustom(swiper, current, total) {
                    return `Фото ${(`${current}`)} из ${(`${total}`)}`;
                },
            }
        }

        this.swiper = new Swiper(this.sliderTarget, {
            modules: [Autoplay, Navigation, Pagination, Virtual, SwiperController],
            slidesPerView: this.typeGallery === 'banners' ? 1 : 'auto',
            resizeObserver: false,
            centeredSlides: true,
            paginationClickable: true,
            loop: this.slideTargets.length > 1,
            resizeReInit: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: this.slideTargets.length > 1 ? this.nextTarget : null,
                prevEl: this.slideTargets.length > 1 ? this.prevTarget : null,
            },
            autoplay: this.typeGallery === 'banners' && {
                delay: 5000,
                disableOnInteraction: true,
            },
            pagination: paginationSlider,
            mousewheel: true,
            renderCustom(swiper, current, total) {
                return `Фото${current}из${total}`;
            },
            breakpoints: this.typeGallery === 'slider' && {
                368: {
                    spaceBetween: 4,
                },
                768: {
                    spaceBetween: 32,
                },
                1087: {
                    spaceBetween: 32,
                },
                1287: {
                    spaceBetween: 40,
                },
            },
            debugger: true,
        });

        this.realSlides = this.swiper.slides.length - this.swiper.loopedSlides * 2;
        this.sliderTranslate = this.wrapperTarget.style;

        this.recalculateHandler();
        // lazyLoad.update();
        // this.swiper.on('slideChange', (slider) => {
        //   this.photoSlider.slideToLoop(slider.realIndex, 0, false);
        // });
    }

    infoSlide() {
        const slides = this.slideInfo;


        if(window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__image-size')[0])){
            if (slides[this.slideIndex] && slides[this.slideIndex].size) {
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__image-size')[0]).html(slides[this.slideIndex].size);
            }
        }

        if(window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__image-type')[0])){
            if (slides[this.slideIndex] && slides[this.slideIndex].url) {
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__image-type')[0]).html(`.${slides[this.slideIndex].url.split('.')[1]}`);
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__download-image')[0]).attr('href', slides[this.slideIndex].url);
            }
        }

        if(window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__slide-description')[0])){
            if (slides[this.slideIndex] && slides[this.slideIndex].description.length > 0) {
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__slide-description')[0]).css('display', 'block');
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__slide-description')[0]).html(slides[this.slideIndex].description);
            } else {
                window.$(window.$(window.$(this.sliderTarget).parents('.gallery-block__wrapper')[0]).find('.photo-modal__slide-description')[0]).css('display', 'none');
            }
        }
    }

    getImageSizeInBytes(imgURL) {
        const request = new XMLHttpRequest();
        request.open('HEAD', imgURL, false);
        request.send(null);
        const headerText = request.getAllResponseHeaders();
        const re = /Content-Length\s*:\s*(\d+)/i;
        re.exec(headerText);
        return parseInt(RegExp.$1, 10);
    }

    roundUp(num, precision) {
        precision = Math.pow(10, precision);
        return Math.ceil(num * precision) / precision;
    }

    getSlide() {
        this.slideTargets.forEach((element) => {
            let infoItem = {
                id: element.dataset.swiperSlideIndex,
                url: element.dataset.articleSliderImg,
                description: element.dataset.articleDescription,
                size: element.dataset.articleSize,

            };
            this.slideInfo.push(infoItem);

            if((element.dataset.articleSliderImg2 && element.dataset.articleSliderImg2.length > 0) || (element.dataset.articleDescription2 && element.dataset.articleDescription2.length > 0) || (element.dataset.articleSize2 && element.dataset.articleSize2.length > 0)){
                let infoItem = {
                    id: element.dataset.swiperSlideIndex,
                    url: element.dataset.articleSliderImg2,
                    description: element.dataset.articleDescription2,
                    size: element.dataset.articleSize2,
                };
                this.slideInfo.push(infoItem);
            }
        });
    }

    processData(doc) {
        if (!doc) {
            return;
        }

        const newContent = doc.querySelector('.article-slider__list');
        const newButton = doc.querySelector('.button-load');
        const newPhotoDetailList = doc.querySelector('.photo-slider__list');
        if (newContent) {
            const fragment = document.createDocumentFragment();
            while (newContent.children.length > 0) {
                fragment.appendChild(newContent.children[0]);
            }

            document.querySelector('.article-slider__list').appendChild(fragment);
        }

        if (newPhotoDetailList) {
            const fragmentPhoto = document.createDocumentFragment();
            while (newPhotoDetailList.children.length > 0) {
                fragmentPhoto.appendChild(newPhotoDetailList.children[0]);
            }
            document.querySelector('.photo-slider__list').appendChild(fragmentPhoto);
            this.photoSlider.update();
            this.getSlide();
            this.infoSlide();
        }
        // lazyLoad.update();
        if (newButton) {
            document.querySelector('.button-load').href = newButton.href;
        } else {
            document.querySelector('.button-load').classList.add('hidden');
        }
    }

    load(event) {
        event.preventDefault();
        const url = event.target.href;
        event.target.classList.add('disabled');
        document.body.classList.add('loading');
        event.target.blur();
        fetch(url)
            .then((response) => response.text())
            .then((text) => {
                const parser = new DOMParser();
                this.processData(parser.parseFromString(text, 'text/html'));
            })
            .finally(() => {
                event.target.classList.remove('disabled');
                document.body.classList.remove('loading');
            });
    }
}
