import $ from 'jquery';
import {BASE_URL} from "../../constants/EnvVariables";
import 'jquery-mask-plugin/dist/jquery.mask.min';
import {snakeKeys} from 'js-convert-case';

export function validateFormInit() {
    $('.public-site__phone-input').mask('+7 (999) 999-99-99', {placeholder: "+7 (___) ___-__-__"});
    $('.constructor-form-in-text:not(.init)').on('submit', function (event) {
        $('.constructor-form-in-text').addClass('init');
        event.stopPropagation();
        event.preventDefault();

        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let errorFields = {};

        const inputWrappers = $(this).find('.constructor-input-field__wrapper');
        let data = {
            path: window.location.href
        };

        $.each(inputWrappers, function (index, item) {
            const errorMessageWrapper = $(item).find('.constructor-input-field__error-message');
            const input = $(item).find(':input')[0];
            const inputName = $(input).attr('name');
            const value = $(input).val();


            data = {
                ...data,
                [inputName]: value,
            };

            $(item).removeClass('error');
            $(errorMessageWrapper).text('');

            let errorMessage;

            switch (inputName) {
                case 'phone':
                    errorFields.phone = value.trim().length === 0;
                    errorMessage = 'Введите номер телефона';
                    break;
                case 'name':
                    errorFields.name = value.trim().length === 0;
                    errorMessage = 'Введите имя';
                    break;
                case 'text':
                    errorFields.text = value.trim().length === 0;
                    errorMessage = 'Введите текст соощения';
                    break;
                case 'email':
                    errorFields.email = !emailPattern.test(value);
                    errorMessage = 'Введите кореектный E-mail';
                    break;
            }

            if (errorFields[inputName]) {
                $(item).addClass('error');
                $(errorMessageWrapper).text(errorMessage);
            }
        });

        const canSend = !Object.values(errorFields).find((item) => item === true);

        if (canSend) {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: BASE_URL + 'api/feedbacks',
                data: snakeKeys(data),
                success: function (data, status, xhr) {
                    alert('Ваше сообщение успешно отправлено');
                },
                error: function (data) {
                    alert('Произошла ошибка');
                }
            })
        }
    });
}
