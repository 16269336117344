import {getSettings} from "../actions/SettingsActions";
import {getNews} from "../actions/NewsActions";
import {getSettingsMainPage} from "../actions/SettingsMainPageAction";
import {getStructure} from "../actions/StructureActions";
import {getColorSchemes} from "../actions/ColorSchemesActions";
import {getSiteSamples} from "../actions/SiteSamplesActions";
import {getOrganisationCategories} from "../actions/OrganisationCategoriesActions";
import {getPages} from "../actions/PagesActions";
import {getPersons} from "../actions/PersonalitiesActions";
import {getSubdivisionGroups} from "../actions/SubdivisionGroupsActions";
import {getSubdivisions} from "../actions/SubdivisionsActions";
import {getManagement} from "../actions/ManagementActions";
import {getFood} from "../actions/FoodActions";


export const getDataFromServer = (storeRedux) => {
    storeRedux.dispatch(getOrganisationCategories());
    storeRedux.dispatch(getSettings());
    storeRedux.dispatch(getNews());
    storeRedux.dispatch(getSettingsMainPage());
    storeRedux.dispatch(getStructure());
    storeRedux.dispatch(getColorSchemes());
    storeRedux.dispatch(getSiteSamples());
    storeRedux.dispatch(getPages());
    storeRedux.dispatch(getPersons());
    storeRedux.dispatch(getSubdivisionGroups());
    storeRedux.dispatch(getSubdivisions());
    storeRedux.dispatch(getManagement());
    storeRedux.dispatch(getFood());
};
