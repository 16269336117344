import * as types from '../constants/ActionTypes';
import {BASE_URL} from '../constants/EnvVariables';
import Cookies from "js-cookie";
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";


export function getPages() {
    return dispatch => {
        dispatch({
            type: types.GET_PAGES_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/pages",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },

            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_PAGES_SUCCESS,
                    info: {pages: data},
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_PAGES_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    }
}

export function editPage(url, json, title, description, keywords, id, page_id) {
    return dispatch => {
        dispatch({
            type: types.EDIT_PAGE_STARTED
        });

        // if(!window.editBlocked){
            return Promise.resolve(
                window.$.ajax({
                    type: "PUT",
                    dataType: 'json',
                    url: BASE_URL + "api/pages/" + id + "?_method=PUT",
                    data: {id: id, json_content: json, seo_title: title, seo_description: description, seo_keywords: keywords, page_id: page_id},
                    headers: {
                        'Authorization': "Bearer " + Cookies.get('site_builder_token')
                    },

                    success: function (data, status, xhr) {
                        dispatch({
                            type: types.EDIT_PAGE_SUCCESS,
                            info: {
                                url: url,
                                jsonContent: json,
                                title: title,
                                description: description,
                                keywords: keywords,
                                page_id: page_id
                            },
                        });
                    },

                    error: function (data) {
                        ajaxErrorRedirect(data);

                        dispatch({
                            type: types.EDIT_PAGE_ERROR,
                            message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                        });
                    }
                })
            );
        // }

    }
}

export function clearPages() {
    return dispatch => {
        dispatch({
            type: types.GET_PAGES_SUCCESS,
            info: {pages: []},
        });
    };
}

export default getPages