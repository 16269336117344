import { Application } from "stimulus";
import {
    WaveController,
    StickyBarController,
    SiteSubsubmenuController,
    ModalWindowController,
    TabsController,
    SelectController
  } from '@make/ds2';
import {
    AccordionController,
    SiteMenuController,
    SiteMenuModalController,
    ViewMoreController,
    ArticleSliderController,
    PhotoSliderController,
    CopyLinkController,
    PageAsideMenuController,
    BackLinkController,
    FooterMenuController,
    SiteSearchController
} from './components/controllers';
import $ from "jquery";
import {validateFormInit} from "./jquery-libs/validation";
import {newsCounter} from "./jquery-libs/newsCounter";
import {getGeneralBanners} from "./jquery-libs/generalBanners";
import DisclaimerPopup from './jquery-libs/disclaimer-popup';

const application = Application.start();
application.register('wave', WaveController);
application.register('sticky-bar', StickyBarController);
application.register('accordion', AccordionController);
application.register('site-menu', SiteMenuController);
application.register('site-subsubmenu', SiteSubsubmenuController);
application.register('site-menu-modal', SiteMenuModalController);
application.register('modal-window', ModalWindowController);
application.register('view-more', ViewMoreController);
application.register('article-slider', ArticleSliderController);
application.register('copy-link', CopyLinkController);
application.register('tabs', TabsController);
application.register('aside-menu', PageAsideMenuController);
application.register('back-link', BackLinkController);
application.register('footer-menu', FooterMenuController);
application.register('photo-slider', PhotoSliderController);
application.register('site-search', SiteSearchController);
application.register('select', SelectController);

window.reloadAsideMenu = function(){
    application.register('aside-menu', PageAsideMenuController);
};

window.reloadGallerySlider = function(){
    application.register('article-slider', ArticleSliderController);
};

$(document).ready(function() {
    validateFormInit();
    newsCounter();
    const disclaimer = new DisclaimerPopup();
    if (!window.localStorage.getItem('gosweb-disclaimer-shown')) {
        disclaimer.showOnce();
    }

    setTimeout(function () {
        getGeneralBanners();
    }, 2000);
});
