import * as types from '../constants/ActionTypes';

const initialState =  [];

export function siteSamples(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_SITE_SAMPLES_STARTED:
            return state;

        case types.GET_SITE_SAMPLES_SUCCESS:
            return action.info;

        case types.GET_SITE_SAMPLES_ERROR:
            return state;

        default:
            return state;
    }
}

export default siteSamples
