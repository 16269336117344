import React, {useEffect} from 'react';
import {useSelector} from "react-redux";



const SetColorScheme = () => {
    const settings = useSelector((state) => state.settings);
    const plugColorSchemes = useSelector((state) => state.colorSchemes);
    const colorScheme = plugColorSchemes.filter((item)=> item.id == settings.colorSchemeId);

    const hexToRgb = (color) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
    };

    const hexToRgbArray = (color) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    };


    const RGBtoHSV = (color) => {
        let r,g,b,h,s,v;
        r= color[0];
        g= color[1];
        b= color[2];
        let min = Math.min( r, g, b );
        let max = Math.max( r, g, b );


        v = max;
        let delta = max - min;
        if( max !== 0 )
            s = delta / max;        // s
        else {
            // r = g = b = 0        // s = 0, v is undefined
            s = 0;
            h = -1;
            return [h, s, undefined];
        }
        if( r === max )
            h = ( g - b ) / delta;      // between yellow & magenta
        else if( g === max )
            h = 2 + ( b - r ) / delta;  // between cyan & yellow
        else
            h = 4 + ( r - g ) / delta;  // between magenta & cyan
        h *= 60;                // degrees
        if( h < 0 )
            h += 360;
        if ( isNaN(h) )
            h = 0;
        return [h,s,v];
    };

    const HSVtoRGB = (color) => {
        let i;
        let h,s,v,r,g,b;
        h= color[0];
        s= color[1];
        v= color[2];
        if(s === 0 ) {
            // achromatic (grey)
            r = g = b = v;
            return [r,g,b];
        }
        h /= 60;            // sector 0 to 5
        i = Math.floor( h );
        let f = h - i;          // factorial part of h
        let p = v * ( 1 - s );
        let q = v * ( 1 - s * f );
        let t = v * ( 1 - s * ( 1 - f ) );
        switch( i ) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            default:        // case 5:
                r = v;
                g = p;
                b = q;
                break;
        }
        return [r,g,b];
    };

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--color-interactive-element", colorScheme[0] ? colorScheme[0].firstColor : '#0050B2'
        );

        let hsv= colorScheme[0] ? RGBtoHSV(hexToRgbArray(colorScheme[0].firstColor)) :  RGBtoHSV(hexToRgbArray('#0050B2'));
        hsv[1] /= 1.5;
        let rgbVisited = HSVtoRGB(hsv);

        root?.style.setProperty(
            "--color-interactive-element-visited",`rgb(${rgbVisited[0]},${rgbVisited[1]},${rgbVisited[2]})`
        );

        root?.style.setProperty(
            "--color-interactive-element-rgba", colorScheme[0] ? `rgba(${hexToRgb(colorScheme[0].firstColor)}, 0.32)` : `rgba(${hexToRgb('#0050B2')}, 0.32)`
        );

        root?.style.setProperty(
            "--color-interactive-element-hover", colorScheme[0] ? colorScheme[0].secondColor : '#0061D9'
        );

        root?.style.setProperty(
            "--color-interactive-element-hover-rgba", colorScheme[0] ? `rgba(${hexToRgb(colorScheme[0].secondColor)}, 0.08)` : `rgba(${hexToRgb('#0061D9')}, 0.8)`
        );

        root?.style.setProperty(
            "--color-interactive-element-active", colorScheme[0] ? colorScheme[0].firstColor : '#0047A2'
        )

    }, [colorScheme]);


    return ''
};

export default SetColorScheme;
