import * as types from '../constants/ActionTypes';
import {createSelector} from "@reduxjs/toolkit";

const initialState = { management: [
        {
            id: '1',
            position: 'Наименование должности',
            personId: null,
            managerId: null,
            subdivisionIds: [],
            level: 1,
        }
    ]};


export function management(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.SET_MANAGEMENT_STARTED:
            return state;

        case types.SET_MANAGEMENT_SUCCESS:
            return {
                ...state,
                management: action.payload,
            };

        case types.SET_MANAGEMENT_ERROR:
            return state;


        case types.GET_MANAGEMENT_STARTED:
            return state;

        case types.GET_MANAGEMENT_SUCCESS:
            return action.payload;

        case types.GET_MANAGEMENT_ERROR:
            return state;

        case types.DELETE_MANAGER:
            const found = state.management.find((item) => item.personId === parseInt(action.payload));

            if (found) {
                if (found.level !== 1) {
                    const filtered = state.management.filter((item) => item.managerId !== parseInt(found.id));

                    return {
                        ...state,
                        management: filtered.filter((item) => item.id !== parseInt(found.id)),
                    }
                } else {
                    return {
                        ...state,
                        ...initialState,
                    }
                }
            }

            return state;
        default:
            return state;
    }
}

export const selectManagement = state => state.management.management;

export const selectMainManager = () => createSelector(
    selectManagement,
    (management) => management.find((manager) => manager.managerId === null || (manager.managerId && manager.managerId.length <= 0)),
);

export const selectManagersNotMain = () => createSelector(
    selectManagement,
    (management) => management.filter((manager) => manager.managerId !== null),
);

export const selectManagerByPersonId = (id) => createSelector(
    selectManagement,
    (management) => management.find((manager) => manager.personId === parseInt(id)),
);

export const selectManagersByManagerId = (id) => createSelector(
    selectManagement,
    (management) => management.filter((manager) => manager.managerId === parseInt(id)),
);
