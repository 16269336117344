import * as types from '../constants/ActionTypes';
import slugify from 'slugify';
import Cookies from "js-cookie";
import {BASE_URL} from "../constants/EnvVariables";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";


function setUrl(deepLevels, type) {
    let url = '/';

    if (deepLevels.secondLevelSlug) {
        if (type === 'add') {
            url += deepLevels.secondLevelSlug + '/'
        }
    }

    if (deepLevels.thirdLevelSlug) {
        if (type === 'add') {
            url += deepLevels.thirdLevelSlug + '/'
        } else {
            url += deepLevels.secondLevelSlug + '/'
        }
    }

    if (deepLevels.fourthLevelSlug) {
        if (type === 'add') {
            url += deepLevels.fourthLevelSlug + '/'
        } else {
            url += deepLevels.thirdLevelSlug + '/'
        }
    }

    return url;
}

export function getStructure() {
    return dispatch => {
        dispatch({
            type: types.GET_STRUCTURE_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/structure",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },

            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_STRUCTURE_SUCCESS,
                    info: {structure: data},
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_STRUCTURE_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    }

}

export function filterStructure(structure, deepLevels) {
    let filterStructure = structure;
    deepLevels.forEach(function (item) {
        filterStructure = filterStructure.filter((itemStructure) => itemStructure.slug === item);
        if (filterStructure.length > 0) {
            filterStructure = filterStructure[0].pages
        }
    });

    return filterStructure
}

export function getPageFromStructure(structure, deepLevels) {
    let filterStructure = structure;
    let rootFilteredStructure = [];
    deepLevels.forEach(function (item) {
        filterStructure = filterStructure.filter((itemStructure) => itemStructure.slug === item);
        if (filterStructure.length > 0) {
            rootFilteredStructure = filterStructure[0];
            filterStructure = filterStructure[0].pages
        }
    });

    return rootFilteredStructure
}

export function editStructure(deepLevels, name, slug, url, active = 0, editable = 0, id, pageId, postfixSlug = 0) {
    let info = {};

    if(postfixSlug){
        slug = slugify(name.toLowerCase() + '--' + postfixSlug);
    }
    else{
        slug = slugify(name.toLowerCase());
    }

    if (editable) {
        url = setUrl(deepLevels, 'edit');
        url = url + slug
    }

    info = {
        name: name,
        slug: slug,
        url: url,
        active: active,
        editable: editable,
        id: id,
        pageId: pageId
    }


    return dispatch => {
        dispatch({
            type: types.EDIT_STRUCTURE_PAGE_STARTED
        });

        if(!window.editBlocked){
            window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/pages/" + id + "?_method=PUT",
                data: snakeKeys(info),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    info.id = data.id;
                    info.slug = data.slug;
                    info.url = data.url;

                    dispatch({
                        type: types.EDIT_STRUCTURE_PAGE_SUCCESS,
                        deepLevels: deepLevels,
                        info: info,
                    });

                    dispatch({
                        type: types.ADD_PAGE_SUCCESS,
                        info: {
                            url: data.url,
                            jsonContent: '',
                            id: data.id
                        },
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.EDIT_STRUCTURE_PAGE_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }
}

export function deleteStructure(deepLevels, slug, id) {
    return dispatch => {
        dispatch({
            type: types.DELETE_STRUCTURE_PAGE_STARTED
        });

        if(!window.editBlocked){
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + "api/pages/" + id,
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.DELETE_STRUCTURE_PAGE_SUCCESS,
                        deepLevels: deepLevels,
                        info: {
                            slug: slug
                        },
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.DELETE_STRUCTURE_PAGE_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }
}

export function updatePriorityStructure(deepLevels, slug, type, id) {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PRIORITY_STRUCTURE_PAGE_STARTED
        });

        if(!window.editBlocked){
            window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/structure_sort",
                data: {id: id, sort: type},
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.UPDATE_PRIORITY_STRUCTURE_PAGE_SUCCESS,
                        deepLevels: deepLevels,
                        info: {
                            slug: slug,
                            type: type
                        },
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.UPDATE_PRIORITY_STRUCTURE_PAGE_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function addStructure(deepLevels, name, slug = null, idParent) {
    const postfixSlug = Math.random().toString(4).substring(2, 4);
    slug = !slug ? slugify(name.toLowerCase() + postfixSlug) : slug;
    const url = setUrl(deepLevels, 'add');

    let info = {
        name: name,
        active: 1,
        editable: 1,
        slug: slug,
        url: url + slug,
        pageId: idParent,
        pages: []
    };

    return dispatch => {
        dispatch({
            type: types.ADD_STRUCTURE_PAGE_STARTED
        });

        if(!window.editBlocked){
            window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/pages",
                data: snakeKeys(info),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    info.id = data.id;
                    info.slug = data.slug;
                    info.url = data.url;

                    dispatch({
                        type: types.ADD_STRUCTURE_PAGE_SUCCESS,
                        deepLevels: deepLevels,
                        info: info
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.ADD_STRUCTURE_PAGE_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }
}

export function clearStructure() {
    return dispatch => {
        dispatch({
            type: types.GET_STRUCTURE_SUCCESS,
            info: {structure: []},
        });
    };
}

export default getStructure
