import { combineReducers } from 'redux';

import { structure } from './structure';
import { pages } from './pages';
import { settings } from './settings';
import { news } from './news';
import { settingsMainPage } from './settingsMainPage';
import { organisationCategories } from './organisationCategories';
import { colorSchemes } from './colorSchemes';
import { siteSamples } from './siteSamples';
import { personalities } from './personalities';
import { subdivisions } from './subdivisions';
import { subdivisionGroups } from './subdivisionGroups';
import { management } from "./management";
import { generalBanners } from "./generalBanners";
import { food } from "./food";
import { errorMessage } from "./errorMessage";

// Манифест для редьюсеров, все необходимые добавляе сюда

const reducer = combineReducers({
  structure,
  pages,
  settings,
  news,
  settingsMainPage,
  organisationCategories,
  colorSchemes,
  personalities,
  subdivisions,
  subdivisionGroups,
  management,
  errorMessage,
  generalBanners,
  food,
  siteSamples
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};
export default rootReducer;
