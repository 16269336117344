import * as types from '../constants/ActionTypes';

const initialState =  [];

export function colorSchemes(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_COLOR_SCHEMES_STARTED:
            return state;

        case types.GET_COLOR_SCHEMES_SUCCESS:
            return action.info;

        case types.GET_COLOR_SCHEMES_ERROR:
            return state;

        default:
            return state;
    }
}

export default colorSchemes
