import {Controller} from 'stimulus';

export default class FooterMenuController extends Controller {
    static targets = ['link'];

    connect() {
        let fontHeight = parseInt(window.getComputedStyle(this.linkTargets[0]).fontSize, 10);
        this.linkTargets.forEach(element => {
            if (fontHeight && (fontHeight * 2) < element.getBoundingClientRect().height) {
                this.element.classList.add('site-footer__menu-wrapper--long')
            }

            fontHeight = element.getBoundingClientRect().height;
          });
    }
}