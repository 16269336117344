import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";
import Cookies from "js-cookie";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getSubdivisionGroups() {
    return dispatch => {
        dispatch({
            type: types.GET_SUBDIVISION_GROUP_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/subdivision_groups",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_SUBDIVISION_GROUP_SUCCESS,
                    payload: {groups: data},
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_SUBDIVISION_GROUP_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    };
}

export function addSubdivisionGroup(group) {
    return dispatch => {
        dispatch({
            type: types.ADD_SUBDIVISION_GROUP_STARTED
        });


        if(!window.editBlocked){
            window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/subdivision_groups",
                data: snakeKeys(group),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    group.id = data.id;
                    dispatch({
                        type: types.ADD_SUBDIVISION_GROUP_SUCCESS,
                        payload: group,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.ADD_SUBDIVISION_GROUP_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function deleteSubdivisionGroup(id) {
    return dispatch => {
        dispatch({
            type: types.DELETE_SUBDIVISION_GROUP_STARTED
        });


        if(!window.editBlocked){
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + "api/subdivision_groups/" + id,
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.DELETE_SUBDIVISION_GROUP_SUCCESS,
                        payload: id,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.DELETE_SUBDIVISION_GROUP_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function editSubdivisionGroup(group) {
    return dispatch => {
        dispatch({
            type: types.EDIT_SUBDIVISION_GROUP_STARTED
        });

        if(!window.editBlocked){
            window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/subdivision_groups/" + group.id,
                data: snakeKeys(group),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    dispatch({
                        type: types.EDIT_SUBDIVISION_GROUP_SUCCESS,
                        payload: group,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.EDIT_SUBDIVISION_GROUP_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function sortSubdivisionGroups(oldIndex, newIndex) {
    return dispatch => {
        dispatch({
            type: types.SORT_SUBDIVISION_GROUPS,
            payload: {oldIndex, newIndex},
        })
    }
}

export function clearSubdivisionGroups() {
    return dispatch => {
        dispatch({
            type: types.GET_SUBDIVISION_GROUP_SUCCESS,
            payload: {groups: []},
        });
    };
}
