import React, {useEffect} from 'react';
import {useSelector} from "react-redux";


const AppendToHead = () => {
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        // window.$('head').append(`${ReactHtmlParser(lz.decompress(lz.decodeBase64(settings.metric ? settings.metric : '')))}`)
        window.$('head title').html(`${settings.organisationName}`)

    }, [settings]);


    return ''
};

export default AppendToHead;