import * as types from '../constants/ActionTypes';

const initialState = {
  banners: [],
  services: [],
  links: [],
};

export function settingsMainPage(state, action) {
  state = state || initialState;

  switch (action.type) {
    case types.GET_SETTINGS_MAIN_PAGE_STARTED:
      return state;

    case types.GET_SETTINGS_MAIN_PAGE_SUCCESS:
      return action.info;

    case types.GET_SETTINGS_MAIN_PAGE_ERROR:
      return state;

    case types.SET_SETTINGS_MAIN_PAGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
